import base from './base'

export default {
  ...base,
  colors: {
    ...base.colors,
    //default
    // shadow1: '#2F80ED',
    // text1: '#ffffff',
    // text2: '#dcdcde',
    // text3: '#888D9B',
    // text4: '#C3C5CB',
    // text5: '#000000',
    shadow1: '#2F80ED',
    text1: '#000000',
    text2: '#000000',
    text3: '#000000',
    text4: '#000000',
    text5: '#000000',

    // backgrounds / greys
    // bg0: '#f1f2f4',
    // bg1: '#FFFDFA',
    // bg2: '#eeeeee',
    // bg3: '#e5e6ec',
    // bg4: '#CED0D9',
    // bg5: '#FFB047',
    // modalBG: 'rgba(0,0,0,0.3)',
    // advancedBG: 'rgba(255,255,255,0.8)',

    bg0: '#FFFFFF',
    bg1: '#C2CBD2',
    bg2: '#a1f7f7',
    bg3: '#5ED4D4',
    bg4: '#000000',
    bg5: '#5ED4D4',
    modalBG: 'rgba(0,0,0,0.3)',
    advancedBG: 'rgba(255,255,255,0.8)',

    //primary colors
    primary1: '#5ED4D4',
    primary4: '#5ED4D4',
    primary5: '#5ED4D4',

    // color text
    primaryText1: '#FFFFFF',
    buttonText: '#ffffff',

    // secondary colors
    secondary1: '#13aab5'
  }
}
